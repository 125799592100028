import * as React from "react"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd"
import { Seo } from "../../components/seo"
import Layout from "../../components/layout"
import PageContent from "../../components/page-content"
import { StoreContext } from "../../context/store-context"
import { Link, navigate } from "gatsby"
// import { JsonDebugger } from "../../components/json-debugger"
const { Title } = Typography

const AccountCreateForm = () => {
  const { registerCustomer, loading } = React.useContext(StoreContext)
  const [form] = Form.useForm()
  const onFinish = (values: any) => {
    // console.log("Success:", values)
    registerCustomer({ variables: { input: values } })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }
  const onReset = () => {
    form.resetFields()
  }
  // const prefixSelector = (
  //   <Form.Item name="prefix" noStyle>
  //     <Select style={{ width: 70 }}>
  //       <Select.Option value="1">+1</Select.Option>
  //       <Select.Option value="44">+44</Select.Option>
  //       <Select.Option value="52">+52</Select.Option>
  //     </Select>
  //   </Form.Item>
  // )
  return (
    <Form
      name="register"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      // initialValues={{ remember: true }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="firstName"
        label="First Name"
        // tooltip="What do you want others to call you?"
        // rules={[{ required: false, message: 'Please input your nickname!', whitespace: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        // tooltip="What do you want others to call you?"
        // rules={[{ required: true, message: 'Please input your nickname!', whitespace: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
            min: 5,
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      {/* <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              )
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item> */}
      {/* <Form.Item
        name="phone"
        label="Phone Number"
        rules={[{ required: true, message: "Please input your phone number!" }]}
      >
        <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
      </Form.Item> */}
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Register
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
          {/* <Button type="link" onClick={() => navigate("/account/login")}>
            Login
          </Button> */}
          {/* <Link to={"/account/login"}>login</Link> */}
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Link to={"/account/login"}>Login instead</Link>
      </Form.Item>
    </Form>
  )
}
// const FormActions = () => {
//   return <Button type="primary">Register</Button>
// }

const CreateAccountPage: React.FC = (data) => {
  const title = "Register Account"
  return (
    <Layout>
      <Seo title={title} />
      <PageContent>
        <Row justify="center">
          <Col span={12}>
            <Card title={<Title>{title}</Title>}>
              <AccountCreateForm />
            </Card>
          </Col>
        </Row>
      </PageContent>
      {/* <JsonDebugger json={data} /> */}
    </Layout>
  )
}

export default CreateAccountPage
